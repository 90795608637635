
<template>
  <div class="TemplateList">
    <div class="page-container">
      <div class="section">
        <!--标题栏-->
        <div class="control-box">
          <div class="cb-left">
            <h1 style="font-size: 20px">点检记录</h1>
          </div>
        </div>

        <!--搜索栏-->
        <div class="control-box" style="padding-bottom: 0;">
          <div class="cb-left">
            <el-form ref="form" inline class="control-item" :model="form" label-width="auto">
              <el-form-item label="设备类型：">
                <el-select
                    v-model="form.deviceId"
                    clearable
                    style="width: 150px"
                    @clear="form.deviceId = undefined"
                >
                  <el-option
                      v-for="item in deviceList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  />
                </el-select>
              </el-form-item>

              <el-form-item label="保养类型：">
                <el-select
                    v-model="form.type"
                    clearable
                    style="width: 150px"
                    @clear="form.type = undefined"
                >
                  <el-option
                      v-for="item in option.maintainTypes"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  />
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button
                    class="control-item"
                    icon="el-icon-search"
                    type="primary"
                    @click="handleFilter"
                >
                  搜索
                </el-button>
              </el-form-item>
            </el-form>
          </div>
          <!--右-->

        </div>

        <div class="table-box">
          <el-table
              v-loading="listLoading"
              :data="templateList"
              element-loading-text="Loading"
              border
              fit
              highlight-current-row
          >
            <el-table-column label="设备名称" align="center">
              <template slot-scope="scope">
                {{ scope.row.deviceName }}
              </template>
            </el-table-column>
            <el-table-column label="保养类型" align="center">
              <template slot-scope="scope">
                {{ scope.row.type }}
              </template>
            </el-table-column>

            <el-table-column label="保养人员" align="center">
              <template slot-scope="scope">
                {{ scope.row.person }}
              </template>
            </el-table-column>

            <el-table-column label="点检时间" align="center">
              <template slot-scope="scope">
                {{ scope.row.date }}
              </template>
            </el-table-column>

            <!--操作-->
            <el-table-column label="操作" align="center" width="120">
              <template slot-scope="scope">
                <el-button
                    type="primary"
                    size="mini"
                    @click="showTemplateDetail(scope.row)"
                >
                  查看
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <!--分页-->
        <div class="pagination-box">
          <el-pagination
              :page-sizes="[10, 20, 30, 40]"
              :page-size="form.count"
              :total="totalCount"
              :current-page="form.page"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/api';
import { manageOauth } from '@/utils/validate';
import convert from '@/utils/convert';
import valid from '@/utils/validator';

export default {
  name: 'inspectionRecords',
  data() {
    return {
      buttons: '',
      deviceList: [],
      listLoading: false,
      templateList: [],
      // 查询
      form: {
        deviceId: '', // 设备类型
        type: -1, // 保养类型
        page: 1,
        count: 10,
      },
      // 下拉选项
      option: {
        // 保养类型
        maintainTypes: [
          { name: '全部', id: -1 },
          { name: '日保养', id: 1 },
          { name: '周保养', id: 2 },
          { name: '月保养', id: 3 },
          { name: '季度保养', id: 4 },
          { name: '半年度保养', id: 5 },
          { name: '年度保养', id: 6 },
        ],
      },
      totalCount: 0,
    };
  },
  created() {
    this.getDeviceList();
    this.getTemplateList();
  },
  methods: {
    // 搜索
    handleFilter() {
      this.form.page = 1
      this.getTemplateList()
    },
    getDeviceList() {
      API.deviceList()
          .then((response) => {
            this.deviceList = response.message.data
            this.deviceList.unshift({name: '全部设备',id: ''})
          })
    },
    // 获取模板列表
    getTemplateList() {
      API.checkList(this.form).then((response) => {
        this.templateList = response.message.data.list;
        this.totalCount = response.message.data.totalCount;
      });
    },
    showTemplateDetail(item) {
      this.$router.push({path:'inspectionDetail',query: {id:item.id}})
    },
    // 分页器
    handleSizeChange(val) {
      this.form.count = val;
      this.getTemplateList();
    },
    // 分页器
    handleCurrentChange(val) {
      this.form.page = val;
      this.getTemplateList();
    },
  },
};
</script>

<style lang="scss" scoped>
#upload {
  position: relative;
  margin: 15px 0;
  width: 300px;
  height: 150px;
  background: rgba(134, 132, 132, 0.5);
  border-radius: 5px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.el-icon-upload {
  display: block;
  width: 50px;
  height: 50px;
  font-size: 50px;
  margin: 10px 50px;
}
.upload-text {
  width:200px;
  text-align: center;
}
.control-box {
  .el-form-item {
    margin-bottom: 22px;
    //margin-right: 15px;
  }
}
</style>
